body {
    //SearchArea中有padding时，如果最小宽度为1000，会导致SearchArea中的内容换行
    min-width: 1010px;
    height: 100%;

    @media screen and(max-width: 768px) {
        overflow-x: hidden;
    }
}

.label-character-2 {

    // 2个汉字的label对齐
    .ant-form-item-label {
        min-width: 49px;
    }
}

.label-character-3 {

    // 3个汉字的label对齐
    .ant-form-item-label {
        min-width: 62px;
    }
}


// 4个汉字的label对齐
.label-character-4 .ant-form-item-label,
.label-character-4 .ant-legacy-form-item-label {
    min-width: 77px;
}


// 5个汉字的label对齐
.label-character-5 .ant-form-item-label,
.label-character-5 .ant-legacy-form-item-label {
    min-width: 94px;
}


// 6个汉字的label对齐
.label-character-6 .ant-form-item-label,
.label-character-6 .ant-legacy-form-item-label {
    min-width: 113px;
}

// 6个汉字的label对齐
.label-character-9 .ant-form-item-label,
.label-character-9 .ant-legacy-form-item-label {
    min-width: 143px;
}

.ant-advanced-search-form {
    .ant-legacy-form-item {
        margin: 3px !important;
    }

    .ant-form-item {
        margin: 3px !important;
    }
}

// 表单
.ant-advanced-inline-form {

    //时间控件宽度自适应
    .ant-picker {
        display: flex;
    }

    .ant-legacy-form-item {
        display: flex;
        margin-bottom: 5px;
    }

    .ant-legacy-form-item-control-wrapper {
        flex: 1;
    }

    .ant-legacy-form-item-with-help {
        margin-bottom: 0;
    }

    // 不是必选的字段，加一个orm-item-optional类
    // 前面也加上一个白色*号用于占位对齐
    .form-legacy-item-optional::before {
        display: inline-block;
        margin-right: 4px;
        color: #ffffff;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}

.ant-form .ant-form-item-control {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


////时间选择器
//.ant-legacy-form-item {
//  .ant-legacy-form-item-children .ant-calendar-picker {
//    width: 100%;
//  }
//}

////table fixed: "right" 表头居中
//.ant-table-fixed-right {
//  .ant-table-row-cell-break-word.ant-table-row-cell-last {
//    text-align: center;
//  }
//}

.action-btns {
    text-align: center;

    .ant-btn {
        padding: 0 3px;
        border: 0;
        height: 19px;
    }

    a {
        padding: 0 3px;
    }
}


.ant-table {
    border: 1px solid #e8e8e8;
    border-bottom: 0;
}


//去掉表单中，标签后的冒号
.ant-form-item-label>label::after {
    content: "" !important;
}

.ant-legacy-form-item-label>label::after {
    content: "" !important;
}

// table 鼠标hover 样式 全局
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background: #e6f7ff;
    border-color: rgba(0, 0, 0, 0.03);
}


// inputNumber的宽度
.ant-input-number {
    width: 100%;
}

// 时间选择器的宽度
.ant-picker {
    width: 100%;
}

// 描述组件的标题
.ant-descriptions-title {
    color: #8590a6;
    font-size: 1.5em;
    font-weight: 500;
}

// 子表样式
.ant-table-expanded-row {
    .ant-spin-container {
        margin: 10px 10px 10px 25px;

        .ant-table-container {
            border-bottom: 1px solid #e8e8e8;
        }
    }
}

// 报表页面
.businessWrap {
    text-align: center;

    .title {
        font-size: 16px;
        color: #424242;
    }

    .links {
        a {
            font-size: 14px;
        }
    }
}

// 弹框的右上角X按钮样式调节
.ant-modal-close {
    color: #00000073;
}

.ant-modal-close-x {
    width: 25px;
    height: 25px;
    line-height: 35px;
    text-align: left;
}


#download-app-span {
    margin-left: 8px;
    top: 1px;
    position: relative;

    img {
        display: block;
        width: 180px;
        position: absolute;
        left: 0;
        top: 20px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
    }

    &:hover {
        img {
            visibility: visible;
            opacity: 1;
            transition: all 0.3s;
        }
    }
}