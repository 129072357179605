.input-group-container {
    display: flex;
    align-items: center;
}

.input-group-container .ant-input-group {
    display: flex;
    flex: 1;
}

.input-group-container .ant-input {
    flex: 1;
}